/* eslint-disable @typescript-eslint/no-explicit-any */
import { TemplateRef, ViewContainerRef, effect, inject, signal } from '@angular/core';

export abstract class CerbosBaseDirective {
	protected readonly templateRef = inject(TemplateRef);
	protected readonly viewContainer = inject(ViewContainerRef);

	abstract kind: any;
	abstract service: any;
	abstract action: any;

	attr = signal(null);

	readonly checker = effect(() => this.checkPermissionFromDirective(this.action, this.attr()));

	checkPermissionFromDirective(action: any, customAttr: any) {
		this.viewContainer.clear();

		const allowed = this.service.checkPermission(action, customAttr);

		if (allowed) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}
}
