import {
	isAprioriEstimateExist,
	isDfEstimateAuthor,
	isDfVerified,
	isPrimaryQuoteOwner,
	isQuoteContributor,
	isQuoteOpen,
	isQuoteOwner
} from './cerbos.conditions';
import { AdminPanelActions, AllRoles, CerbosEffect, CerbosResource, CerbosRole, QuoteActions } from './cerbos.constants';
import { CerbosValidationRequest } from './cerbos.interface';

// Cerbos-based policies
const QuotePolicies = {
	apiVersion: 'api.cerbos.dev/v1',
	resourcePolicy: {
		resource: CerbosResource.Quote,
		rules: [
			// Create Quote (Defines Primary Owner Role)
			{
				actions: [QuoteActions.Create],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request)
			},

			// Create New/Add/Delete Parts to/from the created Quote
			{
				actions: [QuoteActions.AddPart],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request)
			},
			{
				actions: [QuoteActions.DeletePart],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) && isQuoteOpen(request)
			},

			// Save & Run/Re-Run Quote
			{
				actions: [QuoteActions.SaveAndRunSca, QuoteActions.ReRunSca],
				roles: [CerbosRole.MCET],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request)
			},
			{
				actions: [QuoteActions.SaveAndRunSca, QuoteActions.ReRunSca],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) || isQuoteContributor(request)
			},
			{
				actions: [QuoteActions.SaveAndRunSca, QuoteActions.ReRunSca],
				roles: [CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin, CerbosRole.BU],
				effect: CerbosEffect.Deny,
				condition: (request: CerbosValidationRequest) => !isQuoteOwner(request) && !isQuoteContributor(request)
			},

			// Define / Invite Owners
			{
				actions: [QuoteActions.InviteOwners],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request)
			},

			// Define / Invite Contributors
			{
				actions: [QuoteActions.InviteContributors],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) || isQuoteContributor(request)
			},
			// APRIORI Request / Configure aPriori Estimate
			{
				actions: [QuoteActions.RequestAprioriEstimate],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) || isQuoteContributor(request)
			},
			{
				actions: [QuoteActions.VerifyAprioriEstimate],
				roles: [CerbosRole.MCET],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) =>
					!isDfVerified(request) && isAprioriEstimateExist(request) && (isQuoteOwner(request) || isQuoteContributor(request))
			},
			{
				actions: [QuoteActions.UnverifyAprioriEstimate],
				roles: [CerbosRole.GCM, CerbosRole.MCET, CerbosRole.SCP, CerbosRole.Engineer, CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isDfVerified(request) && (isQuoteOwner(request) || isQuoteContributor(request))
			},

			// MANUAL Estimates
			{
				actions: [
					QuoteActions.AddManualEstimate,
					QuoteActions.EditManualEstimate,
					QuoteActions.VerifyManualEstimate,
					QuoteActions.UnverifyManualEstimate
				],
				roles: [CerbosRole.BU],
				effect: CerbosEffect.Deny
			},
			{
				actions: [QuoteActions.AddManualEstimate],
				roles: [CerbosRole.GCM, CerbosRole.MCET, CerbosRole.SCP, CerbosRole.Engineer, CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) || isQuoteContributor(request)
			},
			{
				actions: [QuoteActions.EditManualEstimate],
				roles: [CerbosRole.GCM, CerbosRole.MCET, CerbosRole.SCP, CerbosRole.Engineer, CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isPrimaryQuoteOwner(request) || isDfEstimateAuthor(request)
			},
			{
				actions: [QuoteActions.VerificationActions],
				roles: [CerbosRole.GCM, CerbosRole.MCET, CerbosRole.SCP, CerbosRole.Engineer, CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin],
				effect: CerbosEffect.Allow
			},
			{
				actions: [QuoteActions.VerifyManualEstimate],
				roles: [CerbosRole.GCM, CerbosRole.MCET, CerbosRole.SCP, CerbosRole.Engineer, CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) =>
					!isDfVerified(request) && (isPrimaryQuoteOwner(request) || isDfEstimateAuthor(request))
			},
			{
				actions: [QuoteActions.UnverifyManualEstimate],
				roles: [CerbosRole.GCM, CerbosRole.MCET, CerbosRole.SCP, CerbosRole.Engineer, CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) =>
					isDfVerified(request) && (isPrimaryQuoteOwner(request) || isDfEstimateAuthor(request))
			},

			// Close (Finalize) Quote
			{
				actions: [QuoteActions.Close],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request)
			},
			{
				actions: [QuoteActions.Edit],
				roles: [CerbosRole.MCET],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request)
			},
			{
				actions: [QuoteActions.Edit],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) || isQuoteContributor(request)
			},
			{
				actions: [QuoteActions.Edit],
				roles: [CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin, CerbosRole.BU],
				effect: CerbosEffect.Deny,
				condition: (request: CerbosValidationRequest) => !isQuoteOwner(request) && !isQuoteContributor(request)
			},
			{
				actions: [QuoteActions.AssignRegion],
				roles: [CerbosRole.MCET],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request)
			},
			{
				actions: [QuoteActions.AssignRegion],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) || isQuoteContributor(request)
			},
			{
				actions: [QuoteActions.AssignRegion],
				roles: [CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin, CerbosRole.BU],
				effect: CerbosEffect.Deny,
				condition: (request: CerbosValidationRequest) => !isQuoteOwner(request) && !isQuoteContributor(request)
			},
			{
				actions: [QuoteActions.AssignQuantity],
				roles: [CerbosRole.MCET],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request)
			},
			{
				actions: [QuoteActions.AssignQuantity],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) || isQuoteContributor(request)
			},
			{
				actions: [QuoteActions.AssignQuantity],
				roles: [CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin, CerbosRole.BU],
				effect: CerbosEffect.Deny,
				condition: (request: CerbosValidationRequest) => !isQuoteOwner(request) && !isQuoteContributor(request)
			},
			// Duplicate a Quote
			{
				actions: [QuoteActions.Duplicate],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request)
			},
			// Delete Quotes when no Analysis happened (just created) - a condition
			{
				actions: [QuoteActions.DeleteWhenNoSca],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) && isQuoteOpen(request)
			},

			// View All Results Within Quote
			{
				actions: [QuoteActions.View],
				roles: [CerbosRole.GCM, CerbosRole.MCET, CerbosRole.SCP, CerbosRole.Engineer, CerbosRole.BU],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) || isQuoteContributor(request)
			},
			{
				actions: [QuoteActions.View],
				roles: [CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin],
				effect: CerbosEffect.Allow
			},

			// Search / Filter Similar Parts & Suggested Suppliers within one part
			{
				actions: [QuoteActions.ViewSimilarParts, QuoteActions.ViewSuggestedSuppliers],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) || isQuoteContributor(request)
			},

			// Part Detail Drill-Down
			{
				actions: [QuoteActions.ViewPart],
				roles: AllRoles,
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOwner(request) || isQuoteContributor(request)
			},

			// Configure / Modify Part Attributes for Quote Only (not write to Raw Parts Table)
			{
				actions: [QuoteActions.ConfigurePart],
				roles: [CerbosRole.GCM, CerbosRole.SCP, CerbosRole.Engineer, CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin, CerbosRole.BU],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOpen(request) && (isQuoteOwner(request) || isQuoteContributor(request))
			},
			{
				actions: [QuoteActions.ConfigurePart],
				roles: [CerbosRole.SystemAdmin, CerbosRole.BusinessAdmin, CerbosRole.BU],
				effect: CerbosEffect.Deny,
				condition: (request: CerbosValidationRequest) => isQuoteOpen(request) && !isQuoteOwner(request) && !isQuoteContributor(request)
			},
			{
				actions: [QuoteActions.ConfigurePart],
				roles: [CerbosRole.MCET],
				effect: CerbosEffect.Allow,
				condition: (request: CerbosValidationRequest) => isQuoteOpen(request) && isQuoteOwner(request)
			},

			// Search Across Quotes of Membership (Owner or Contributor)
			{
				actions: [QuoteActions.Search],
				roles: AllRoles,
				effect: CerbosEffect.Allow
			}
		]
	}
};

const AdminPanelPolicies = {
	apiVersion: 'api.cerbos.dev/v1',
	resourcePolicy: {
		resource: CerbosResource.AdminPanel,
		rules: [
			{
				actions: [AdminPanelActions.Access],
				roles: [CerbosRole.BusinessAdmin],
				effect: CerbosEffect.Allow
			}
		]
	}
};

export const CerbosPolicies = [QuotePolicies, AdminPanelPolicies];
