import { CerbosValidationRequest } from './cerbos.interface';

export const isQuoteOpen = (request: CerbosValidationRequest) => request.resource.attr.quote.status === 'Open';

export const isQuoteOwner = (request: CerbosValidationRequest) =>
	(request.principal.attr.userData.ownership.owns ?? []).includes(request.resource.attr.quote.mi6_quote_id);

export const isQuoteContributor = (request: CerbosValidationRequest) =>
	(request.principal.attr.userData.ownership.contributes ?? []).includes(request.resource.attr.quote.mi6_quote_id);

export const isPrimaryQuoteOwner = (request: CerbosValidationRequest) => request.principal.id === request.resource.attr.quote.created_by;

export const isDfEstimateAuthor = (request: CerbosValidationRequest) => request.principal.id === request.resource.attr.dfe.created_by;

export const isDfVerified = (request: CerbosValidationRequest) => request.resource.attr.dfe.verified;
export const isAprioriEstimateExist = (request: CerbosValidationRequest) =>
	request.resource.attr.dfe.provider === 'apriori' && request.resource.attr.dfe.estimate !== null;
