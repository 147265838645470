import { gql } from 'apollo-angular';

export const GetQuotesList = gql`
	{
		getQuotesList {
			description
			customer
			nb_quotewin_quote_number
			end_date
			mi6_quote_id
			start_date
			status
			sca_run_status
			is_new_business
			number_of_parts_in
			parts_sent_for_sca
			last_modified_date
		}
	}
`;

export const GetQuoteById = gql`
	query GetQuoteById($quoteId: String!) {
		getQuoteById(quoteId: $quoteId) {
			description
			customer
			nb_quotewin_quote_number
			parent_quotewin_number
			parent_quote
			end_date
			mi6_quote_id
			start_date
			created_date
			created_by
			status
			sca_run_status
			is_new_business
			number_of_parts_in
			parts_sent_for_sca
			last_modified_date
		}
	}
`;

export const GetQuoteStatusById = gql`
	query GetQuoteById($quoteId: String!) {
		getQuoteById(quoteId: $quoteId) {
			mi6_quote_id
			status
			sca_run_status
		}
	}
`;

export const GetPaginatedQuotes = gql`
	query GetPaginatedQuotes($agModel: QuoteTableAgModelInput, $pagination: PaginationInput, $search: QuoteTableSearchInput) {
		getQuotesTable(agModel: $agModel, pagination: $pagination, search: $search) {
			items {
				description
				status
				created_by
				last_modified_date
				mi6_quote_id
				number_of_parts_in
				parts_sent_for_sca
				parts_sent_for_sca_completed
				sca_run_status
				df_requested
				created_by
				created_by_fullname
				updated_by_fullname
				customer
				nb_quotewin_quote_number
				is_new_business
			}
			hasMore
			total
		}
	}
`;

export const CreateQuote = gql`
	mutation CreateQuote($params: CreateQuoteInput!) {
		createQuote(params: $params) {
			description
			customer
			nb_quotewin_quote_number
			parent_quotewin_number
			parent_quote
			end_date
			mi6_quote_id
			start_date
			status
			sca_run_status
			is_new_business
			number_of_parts_in
			last_modified_date
		}
	}
`;

export const UpdateQuote = gql`
	mutation UpdateQuote($quoteId: String!, $quote: UpdateQuoteInput!) {
		updateQuote(quoteId: $quoteId, quote: $quote) {
			description
			customer
			nb_quotewin_quote_number
			parent_quotewin_number
			parent_quote
			end_date
			mi6_quote_id
			start_date
			status
			sca_run_status
			is_new_business
			number_of_parts_in
			parts_sent_for_sca
			last_modified_date
		}
	}
`;

export const CloseQuote = gql`
	mutation CloseQuote($quoteId: String!) {
		closeQuote(quoteId: $quoteId) {
			mi6_quote_id
			status
			end_date
		}
	}
`;

export const InviteOwnersToQuote = gql`
	mutation InviteOwnersToQuote($quoteId: String!, $usersData: [UserOwnershipInviteParamsInput!]!) {
		inviteOwnersToQuote(quoteId: $quoteId, usersData: $usersData) {
			success
		}
	}
`;

export const InviteContributorsToQuote = gql`
	mutation InviteContributorsToQuote($quoteId: String!, $usersData: [UserOwnershipInviteParamsInput!]!) {
		inviteContributorsToQuote(quoteId: $quoteId, usersData: $usersData) {
			success
		}
	}
`;

export const DeleteQuote = gql`
	mutation deleteQuote($quoteId: String!) {
		deleteQuote(quoteId: $quoteId) {
			success
		}
	}
`;

export const DuplicateQuote = gql`
	mutation DuplicateQuote($options: DuplicateOptionsInput!, $quoteId: String!) {
		duplicateQuote(options: $options, quoteId: $quoteId) {
			mi6_quote_id
		}
	}
`;

export const HasRawParts = gql`
	query HasRawParts($partIds: [String!], $quoteId: String!) {
		hasRawParts(partIds: $partIds, quoteId: $quoteId) {
			is_raw_part
			jpn
			mi6_quote_part_id
			mpn
		}
	}
`;
