/* eslint-disable @typescript-eslint/no-explicit-any */
import { IServerSideGetRowsParams } from '@ag-grid-community/core';
import { Injectable, inject } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { DuplicateOptionsInput, PartMeta, Quote, QuotesTableSearchParams, UserOwnershipInviteParams } from '@interfaces/quote.interface';
import { getMappedFilterModel } from '@utils/ag-grid-filter-mapper.utils';
import { Observable, catchError, map, of } from 'rxjs';
import { QuoteWithAttributes } from 'src/app/modules/dashboard/components/quotes-table/quotes-table.interface';
import * as QuoteQueries from '../graphql/quotes.queries';
import { DeleteQuote } from '../graphql/quotes.queries';
import { GqlServerService } from './gql-server.service';

export interface QuoteCreateParams {
	partNumberType: string;
	partNumbers: string[];
	isNewBusiness: boolean;
	customer?: string | null;
	description?: string | null;
	qwNumber?: string | null;
}

interface GetQuotesResponse {
	getQuotesTable: {
		items: QuoteWithAttributes[];
	};
}

@Injectable({
	providedIn: 'root'
})
export class QuotesDataService {
	private readonly gql = inject(GqlServerService).main;

	getQuote(quoteId: string): Observable<Quote> {
		return this.gql
			.query({
				query: QuoteQueries.GetQuoteById,
				variables: { quoteId }
			})
			.pipe(map((res: any) => res.data.getQuoteById));
	}

	getQuoteStatus(quoteId: string): Observable<Quote> {
		return this.gql
			.query({
				query: QuoteQueries.GetQuoteStatusById,
				variables: { quoteId }
			})
			.pipe(map((res: any) => res.data.getQuoteById));
	}

	createQuote(params: QuoteCreateParams): Observable<any> {
		return this.gql
			.mutate({
				mutation: QuoteQueries.CreateQuote,
				variables: { params }
			})
			.pipe(map((res: any) => res.data.createQuote));
	}

	updateQuote(quoteId: string, quote: any): Observable<Quote> {
		return this.gql
			.mutate({
				mutation: QuoteQueries.UpdateQuote,
				variables: { quoteId, quote }
			})
			.pipe(map((res: any) => res.data.updateQuote));
	}

	closeQuote(quoteId: string): Observable<Quote> {
		return this.gql
			.mutate({
				mutation: QuoteQueries.CloseQuote,
				variables: { quoteId }
			})
			.pipe(map((res: any) => res.data.closeQuote));
	}

	getQuotes(): Observable<Quote[]> {
		return this.gql
			.query({
				query: QuoteQueries.GetQuotesList
			})
			.pipe(map((res: any) => res.data.getQuotesList));
	}

	getAgQuotes(
		params: IServerSideGetRowsParams<QuoteWithAttributes>,
		searchParams?: QuotesTableSearchParams | null
	): Observable<{ success: boolean; rows: QuoteWithAttributes[] }> {
		return this.gql
			.query<GetQuotesResponse>({
				query: QuoteQueries.GetPaginatedQuotes,
				variables: {
					agModel: {
						filterModel: getMappedFilterModel<QuoteWithAttributes>(params),
						sortModel: params.request.sortModel
					},
					pagination: {
						limit: (params.request.endRow ?? 100) - (params.request.startRow ?? 0),
						offset: params.request.startRow
					},
					search: searchParams && {
						field: searchParams.field,
						value: searchParams.value
					}
				}
			})
			.pipe(
				map((res: ApolloQueryResult<GetQuotesResponse>) => ({
					success: true,
					rows: res.data.getQuotesTable.items
				})),
				catchError(() => {
					return of({
						success: false,
						rows: []
					});
				})
			);
	}

	inviteOwnersToQuote(quoteId: string, usersData: UserOwnershipInviteParams[]): Observable<any> {
		return this.gql.mutate<GetQuotesResponse>({
			mutation: QuoteQueries.InviteOwnersToQuote,
			variables: {
				quoteId,
				usersData
			}
		});
	}

	inviteContributorsToQuote(quoteId: string, usersData: UserOwnershipInviteParams[]): Observable<any> {
		return this.gql.mutate<GetQuotesResponse>({
			mutation: QuoteQueries.InviteContributorsToQuote,
			variables: {
				quoteId,
				usersData
			}
		});
	}

	deleteQuote(quoteId: string): Observable<boolean> {
		return this.gql
			.mutate<{ deleteQuote: { success: boolean } }>({
				mutation: DeleteQuote,
				variables: { quoteId }
			})
			.pipe(
				map(result => result.data?.deleteQuote.success ?? false),
				catchError(error => {
					console.error('Error deleting quote:', error);
					throw error;
				})
			);
	}

	duplicateQuote(options: DuplicateOptionsInput, quoteId: string) {
		return this.gql
			.mutate({
				mutation: QuoteQueries.DuplicateQuote,
				variables: { options, quoteId }
			})
			.pipe(
				map((result: any) => ({ quoteId: result.data?.duplicateQuote.mi6_quote_id, error: null })),
				catchError(error =>
					of({
						quoteId: null,
						error
					})
				)
			);
	}

	checkForRawPartsExistence(partIds: string[] | null, quoteId: string): Observable<PartMeta[]> {
		return this.gql
			.query({
				query: QuoteQueries.HasRawParts,
				variables: { partIds, quoteId }
			})
			.pipe(
				map((result: any) => result.data?.hasRawParts),
				catchError(error => {
					console.error('Error while checking for raw parts:', error);
					throw error;
				})
			);
	}
}
