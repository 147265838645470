import { gql } from 'apollo-angular';

export const GetPartInfoByJpn = (fields: string[]) => gql`
	query AllParts($jpn: String!) {
		getPartsByJpn(jpn: $jpn) {
			${fields.join('\n')}
		}
	}
`;

export const GetPartInfoByMpn = (fields: string[]) => gql`
	query AllParts($mpn: String!) {
		getPartsByMpn(mpn: $mpn) {
			${fields.join('\n')}
		}
	}
`;

export const SearchByJpn = gql`
	query AllParts($jpn: String!) {
		getPartsByJpn(jpn: $jpn) {
			CI_JPN
		}
	}
`;

export const SearchByMpn = gql`
	query AllParts($mpn: String!) {
		getPartsByMpn(mpn: $mpn) {
			CI_Customer_PN
		}
	}
`;
