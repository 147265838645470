/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { catchError, map } from 'rxjs';
import { PartNumberType } from '../enums/part-number-type.enum';
import * as RawPartsQueries from '../graphql/raw-parts.queries';
import { GqlServerService } from './gql-server.service';

@Injectable({
	providedIn: 'root'
})
export class RawPartsDataService {
	private readonly gql = inject(GqlServerService).main;

	getPartInfoByPn(pn: string, fields: string[], partNumberType: PartNumberType): any {
		const query = {
			JPN: {
				query: RawPartsQueries.GetPartInfoByJpn(fields),
				variables: { jpn: pn }
			},
			MPN: {
				query: RawPartsQueries.GetPartInfoByMpn(fields),
				variables: { mpn: pn }
			}
		}[partNumberType];

		const mapperFn = {
			JPN: (res: any) => res.data.getPartsByJpn[0],
			MPN: (res: any) => res.data.getPartsByMpn[0]
		}[partNumberType];

		return this.gql.query(query as any).pipe(map(mapperFn));
	}

	searchByPartNumber(pn: string, partNumberType: PartNumberType) {
		const query = {
			JPN: {
				query: RawPartsQueries.SearchByJpn,
				variables: { jpn: pn }
			},
			MPN: {
				query: RawPartsQueries.SearchByMpn,
				variables: { mpn: pn }
			}
		}[partNumberType];
		const mapperFn = {
			JPN: (res: any) => res.data.getPartsByJpn.map((p: { CI_JPN: string }) => p.CI_JPN),
			MPN: (res: any) => res.data.getPartsByMpn.map((p: { CI_Customer_PN: string }) => p.CI_Customer_PN)
		}[partNumberType];

		return this.gql.query(query as any).pipe(
			map(mapperFn),
			catchError(() => [])
		);
	}
}
